import {
    Box,
    Flex,

    Img,
    Text,
    Divider,
    Button,

    Tab,
    Tabs,
    TabPanel,
    TabPanels,
    TabList,
    useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import { GooglePlayButton, AppStoreButton } from "react-mobile-app-button";
// import Navbar from "../components/Others/Navbar";
import logo from '../assets/HerVest Logo.svg'
import loanlanding from '../assets/landingloan.png'
import loanwatermark from '../assets/coinwatermark.png'
import testcoinwm from '../assets/testcoinwm.png'
import chevronRight from '../assets/chevron.svg'
import businessIcon from '../assets/businessPink.svg'
import loanbggradient from '../assets/loanbggradient.png'
import footerPhoneImg from '../assets/mobileappfooter.png'
import bgmobile from '../assets/bgMobileApp.png'
import bgFooter from '../assets/bgfooter.png'
import personlogo from '../assets/personlogopink.svg'
import { useNavigate } from "react-router-dom";

import CountUp from 'react-countup';
import { CarouselProvider, Slider, Slide, } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';


const NewLandingPage = () => {
    // const dispatch = useDispatch();
    const navigate = useNavigate();
    const APKUrl = "https://play.google.com/store/apps/details?id=com.hervest";
    const IOSUrl = "https://apps.apple.com/ng/app/hervest-save-invest-loans/id1509714516";

    const paddingX = useBreakpointValue({ base: 4, md: 50 });
    const textAlign = useBreakpointValue({ base: "center", md: "left" });
    const direction = useBreakpointValue({ base: "column", md: "row" });

    const imgWidth = useBreakpointValue({ base: "100%", md: "42%" });

    const headWidth = useBreakpointValue({ base: "100%", md: "42%" });


    const paddingXX = useBreakpointValue({ base: 4, md: 70 });
    const direction2 = useBreakpointValue({ base: "column", md: "row" });
    const boxWidth = useBreakpointValue({ base: "100%", md: "40%" });

    const whyWidth = useBreakpointValue({ base: "100%", md: "45%" });

    const marginX = useBreakpointValue({ base: 0, md: "50px" });
    const marginTop = useBreakpointValue({ base: 4, md: 0 });


    const paddingXXX = useBreakpointValue({ base: 4, md: 40 });
    const directionXXX = useBreakpointValue({ base: "column", md: "row" });
    const boxWidthXXX = useBreakpointValue({ base: "100%", md: "45%" });
    const marginXXX = useBreakpointValue({ base: 0, md: "50px" });
    const marginTopXXX = useBreakpointValue({ base: 4, md: 0 });
    const justifyContentXXX = useBreakpointValue({ base: "center", md: "start" });

    const paddingXTab = useBreakpointValue({ base: 4, md: 200 });
    const fontSizeTab = useBreakpointValue({ base: "12px", md: "15px" });

    const titleFontSize = useBreakpointValue({ base: "30px", md: "40px" });
    const subtitleFontSize = useBreakpointValue({ base: "20px", md: "25px" });

    const titleFontSizeLL = useBreakpointValue({ base: "40px", md: "50px" });
    const textMargin = useBreakpointValue({ base: "0", md: "10px" });

    const textPaddingX = useBreakpointValue({ base: 4, md: 50 });

    const boxWidthMM = useBreakpointValue({ base: "90%", md: "45%" });
    const imgWidthMM = useBreakpointValue({ base: "50%", md: "30%" });
    const fontSizeMM = useBreakpointValue({ base: "26px", md: "30px" });

    const heightCarousel = useBreakpointValue({ base: 90, md: 55 });

    const naturalSlideHeight = useBreakpointValue({ base: 900, md: 450 });
    const naturalSlideWidth = useBreakpointValue({ base: '95%', md: 1132 });

    const midBoxWidth = useBreakpointValue({ base: '90%', md: '55%' });

    const marginB = useBreakpointValue({ base: 10, md: 0 });
    const marginT = useBreakpointValue({ base: 10, md: 3 });

    const playButtonWidth = useBreakpointValue({ base: 150, md: 200 });
    const fontSizz = useBreakpointValue({ base: '30px', md: '55px' });

    const mlll = useBreakpointValue({ base: 30, md: 110 });



    return (

        <Box
            backgroundImage={testcoinwm}
            backgroundSize={'contain'}
            marginTop={50}>

            <Img src={logo} marginLeft={mlll} objectFit="contain" w={["30%", "10%"]} />

            <Flex
            backgroundColor={'whiteAlpha.700'}
                px={paddingX}
                justify="space-around"
                mt="50px"
                align="start"
                direction={direction}
                wrap="wrap"
            >

                <Flex direction="column" width={headWidth} align={textAlign}>

                    <CarouselProvider
                        naturalSlideWidth={naturalSlideWidth}
                        naturalSlideHeight={naturalSlideHeight}
                        orientation='horizontal'
                        totalSlides={3}
                        isPlaying='true'
                    >

                        <Slider>

                        <Slide index={0}>
                                <Text
                                    mt="65px"
                                    color="#540A45"
                                    fontWeight="800"
                                    fontSize={{ base: "52px", md: "60px" }}
                                    fontFamily="heading"
                                    lineHeight={1}
                                >
                                    Get a Loan
                                </Text>
                                <Text
                                    color="#540A45"
                                    fontWeight="800"
                                    fontSize={{ base: "52px", md: "60px" }}
                                    fontFamily="heading"
                                >
                                    in Minutes
                                </Text>


                            </Slide>

                        
                            <Slide index={1}>
                                <Text
                                    mt="65px"
                                    color="#540A45"
                                    fontWeight="800"
                                    fontSize={{ base: "72px", md: "60px" }}
                                    fontFamily="heading"
                                    lineHeight={1}
                                >
                                    Avoid Financial
                                </Text>
                                <Text
                                    color="#540A45"
                                    fontWeight="800"
                                    fontSize={{ base: "32px", md: "60px" }}
                                    fontFamily="heading"
                                >
                                    Stress
                                </Text>


                            </Slide>

                            
                            <Slide index={2} >
                                <Text
                                    mt="65px"
                                    color="#540A45"
                                    fontWeight="800"
                                    fontSize={{ base: "32px", md: "60px" }}
                                    fontFamily="heading"
                                    lineHeight={1}
                                >
                                    Reach your goals
                                </Text>
                                <Text
                                    color="#540A45"
                                    fontWeight="800"
                                    fontSize={{ base: "32px", md: "60px" }}
                                    fontFamily="heading"
                                >
                                    with ease
                                </Text>

                            </Slide>


                        </Slider>

                    </CarouselProvider>


                    <Text my="10px" textAlign={textAlign}>
                        No Paperwork, Competitive rates, Flexible repayment terms
                    </Text>

                    <Button
                        bg="#540445"
                        mt="15px"
                        borderRadius="6px"
                        fontSize="12px"
                        fontWeight="500"
                        color="white"
                        p="13px 32px"
                        width={'200px'}
                        _hover={{
                            color: "#540445",
                            border: "1px solid #540445",
                            bg: "white",
                        }}
                        onClick={() => {
                            navigate("/home");
                        }}
                        textTransform="uppercase"
                    >
                        APPLY FOR A LOAN
                    </Button>

                </Flex>


                <Img src={loanlanding} objectFit="contain" w={imgWidth} mt={{ base: 4, md: 0 }} />
            </Flex>


            <Flex justify='space-around' px='5' py='5' align="center" mt="50px" direction='column' backgroundColor='#F2C9E3'  >

                <Text color="#540A45"
                    marginX={15}
                    fontWeight="700"
                    fontSize={{ base: "32px", md: "50px" }}
                    fontFamily="heading"
                    textAlign={'center'}
                    mb={0} >
                    Get Loans for as low as ₦5,000.
                </Text>
                <Text color="#540A45"
                    marginX={15}
                    fontWeight="700"
                    fontSize={{ base: "32px", md: "50px" }}
                    fontFamily="heading"
                    textAlign={'center'}
                    mb={0} >
                    For as much as ₦10,000,000.
                </Text>


            </Flex>



            <Flex justify='space-around' px='5' py='5' align="center" mt="20px" direction='column' >

                <Text color="#540A45"
                    marginX={15}
                    fontWeight="600"
                    fontSize="36px"
                    fontFamily="heading"
                    textAlign={'center'}
                    mb={3} >
                    What’s HerVest Loans About?
                </Text>

                <Text fontWeight="600"
                    fontSize="24px" >HerVest loans provide tailored financing for individuals and business owners. </Text>
                <Text fontWeight="600"
                    fontSize="24px" >Our mission is to make access to loans simple, fast, and secure,</Text>
                <Text fontWeight="600"
                    fontSize="24px" > ensuring that you can reach your financial goals. </Text>

            </Flex>


            <Flex
                px={paddingXX}
                direction={direction2}
                mt="80px"
                mb="30px"
                justify="space-around"
            >

                <Box
                    width={whyWidth}
                    p="3px"
                    borderRadius="15px"
                    mb={marginTop}
                    alignSelf={'center'}

                >


                    <Text
                        color="#540A45"
                        fontWeight="700"
                        fontSize={fontSizz}
                        fontFamily="heading"
                        textAlign={'center'}
                        mb={3}

                    >
                        Who Can Apply for HerVest Loans?
                    </Text>
                </Box>

                <Box
                    border="1px solid black"
                    width={boxWidth}
                    mx={marginX}
                    boxShadow="1px 3px 4px 4px rgba(2, 2, 2, .1)"
                    bg="whiteAlpha.800"
                    borderRadius="15px"
                    px="30px"
                    pt="40px"
                    pb="30px"
                    mb={marginTop}
                >
                    <Img src={personlogo} />
                    <Text
                        color="#540A45"
                        fontWeight="700"
                        fontSize="25px"
                        fontFamily="heading"
                        my="5px"
                    >
                        Salary Earners
                    </Text>
                    <Text my="5px">
                    We want you to maintain focus at work while meeting your financial needs with the support of HerVest loans.
                    </Text>
                    <Flex onClick={() => {
                            navigate("/home");
                        }}
                         direction="row" align="center" mt="15px">
                        <Text>Apply now</Text>
                        <Img src={chevronRight} h="15px" ml="10px" />
                        <Img src={chevronRight} h="15px" />
                        <Img src={chevronRight} h="15px" />
                    </Flex>
                </Box>

                <Box
                    border="1px solid black"
                    width={boxWidth}
                    boxShadow="1px 3px 4px 4px rgba(2, 2, 2, .1)"
                    bg="whiteAlpha.800"
                    borderRadius="15px"
                    px="30px"
                    pt="40px"
                    pb="30px"
                    mb={marginTop}
                >
                    <Img src={businessIcon} />
                    <Text
                        color="#540A45"
                        fontWeight="700"
                        fontSize="25px"
                        fontFamily="heading"
                        my="5px"
                    >
                        Business Owners
                    </Text>
                    <Text my="5px">
                    We understand that money is vital to achieving your business goals. HerVest offers access to low-interest loans that allow you to grow and expand your business.
                    </Text>
                    <Flex onClick={() => {
                            navigate("/home");
                        }} direction="row" align="center" mt="15px">
                        <Text>Apply now</Text>
                        <Img src={chevronRight} h="15px" ml="10px" />
                        <Img src={chevronRight} h="15px" />
                        <Img src={chevronRight} h="15px" />
                    </Flex>
                </Box>

            </Flex>


            <Flex
                backgroundImage={loanbggradient}
                paddingY={20}
                justify='space-around'
                align={textAlign}
                direction='row'
                flexWrap="Wrap"

            >

                <Box className="flex flex-col" align={textAlign}>

                    <Text
                        textAlign={textAlign}
                        marginTop="65px"
                        color="#540A45"
                        fontWeight="600"
                        fontSize="32px"
                        fontFamily='heading'
                        lineHeight={1}
                        marginBottom={'20px'}
                    >
                        Why People Choose Us?
                    </Text>


                    <Text textAlign={textAlign} >
                        People prefer HerVest loans for many reasons
                    </Text>



                    <Button
                        bg="#540445"
                        my="15px"
                        borderRadius="6px"
                        fontSize="12px"
                        fontWeight="500"
                        color="white"
                        textAlign={'center'}
                        p="13px 32px"
                        _hover={{
                            color: "#540445",
                            border: "1px solid #540445",
                            bg: "white",
                        }}
                        onClick={() => {
                            navigate("/home");
                        }}
                        textTransform="uppercase"
                    >
                        APPLY FOR A LOAN
                    </Button>

                </Box>

                <Box width={midBoxWidth} className="flex flex-col">

                    <Box
                        borderWidth='.5px'
                        border='border' borderColor='#540A45'
                        backgroundColor='whiteAlpha.700'
                        dropShadow='xl'
                        borderRadius='15px' paddingX='30px' paddingTop='10px' paddingBottom='10px' >

                        <Text
                            color="#540A45"
                            fontWeight="700"
                            fontSize="18px"
                            fontFamily='heading'
                            marginY='2px'
                        >
                            Easy Application Process
                        </Text>

                        <Text marginY='5px'>
                            Our process is simple, quick, and paperless
                        </Text>

                    </Box>


                    <Box
                        borderWidth='.5px'
                        border='border' borderColor='#540A45'
                        backgroundColor='whiteAlpha.700'
                        dropShadow='xl'
                        marginY='3'
                        borderRadius='15px' paddingX='30px' paddingTop='10px' paddingBottom='10px' >

                        <Text
                            color="#540A45"
                            fontWeight="700"
                            fontSize="18px"
                            fontFamily='heading'
                            marginY='2px'
                        >
                            Single-digit Interest Rates
                        </Text>

                        <Text marginY='5px'>
                            Our interest rate is capped at 4% and of course, the best rate you can find in the market.
                        </Text>

                    </Box>


                    <Box
                        borderWidth='.5px'
                        border='border' borderColor='#540A45'
                        backgroundColor='whiteAlpha.700'
                        dropShadow='xl'
                        marginY='3'
                        borderRadius='15px' paddingX='30px' paddingTop='10px' paddingBottom='10px'
                    >

                        <Text
                            color="#540A45"
                            fontWeight="700"
                            fontSize="18px"
                            fontFamily='heading'
                            marginY='2px'
                        >
                            Flexible Repayment Terms
                        </Text>

                        <Text marginY='5px'>
                            Whether you prefer monthly, quarterly, or annual installments, we have options to accommodate your cash flow. Our goal is to provide you with a repayment plan that maintains financial stability.
                        </Text>

                    </Box>


                    <Box
                        borderWidth='.5px'
                        border='border' borderColor='#540A45'
                        backgroundColor='whiteAlpha.700'
                        dropShadow='xl'
                        borderRadius='15px' paddingX='30px' paddingTop='10px' paddingBottom='10px'
                    >

                        <Text
                            color="#540A45"
                            fontWeight="700"
                            fontSize="18px"
                            fontFamily='heading'
                            marginY='2px'
                        >
                            Support
                        </Text>

                        <Text marginY='5px'>
                            You get access to 24/7 tailored support to help you make a successful application
                        </Text>

                    </Box>

                </Box>


            </Flex>


            <Flex
                wrap={'wrap'}
                paddingY='10px'
                mt="30px"
                direction='column'
            // backgroundColor='#F2C9E3'
            >

                <Text color="#540A45"
                    fontWeight="700"
                    fontSize="48px"
                    fontFamily="heading"
                    textAlign={'center'}
                >
                    Requirements
                </Text>
                <Flex
                    backgroundImage={loanwatermark}
                    px={paddingXXX}
                    direction={directionXXX}
                    mt="20px"
                    mb="60px"
                    justify={justifyContentXXX}

                >

                    <Box
                        border="1px solid black"
                        width={boxWidthXXX}
                        mx={marginXXX}
                        boxShadow="1px 3px 4px 4px rgba(2, 2, 2, .1)"
                        bg="whiteAlpha.800"
                        borderRadius="15px"
                        px="30px"
                        pt="40px"
                        pb="30px"
                        mb={marginTopXXX}
                    >
                        <Img src={personlogo} color="pink" />
                        <Text
                            color="#540A45"
                            fontWeight="700"
                            fontSize="21px"
                            fontFamily="heading"
                            my="5px"
                        >
                            Personal Loan
                        </Text>

                        {["Must reside in Lagos", "BVN", "Utility Bill", "Government ID ( International passport, Voter’s ID)", "6 Months Bank Statement"].map((item, index) => (
                            <Flex alignItems="center" justify="left" key={index}>
                                <Box width={2} height={2} mr={4} bg="#FA2A55" borderRadius="full" />
                                <Text my="5px">{item}</Text>
                            </Flex>
                        ))}
                    </Box>

                    <Box
                        border="1px solid black"
                        width={boxWidthXXX}
                        boxShadow="1px 3px 4px 4px rgba(2, 2, 2, .1)"
                        bg="whiteAlpha.800"
                        borderRadius="15px"
                        px="30px"
                        pt="40px"
                        pb="30px"
                        mb={marginTopXXX}
                    >
                        <Img src={businessIcon} />
                        <Text
                            color="#540A45"
                            fontWeight="700"
                            fontSize="21px"
                            fontFamily="heading"
                            my="5px"
                        >
                            Business Loan
                        </Text>

                        {["Must reside in Lagos", "BVN", "Utility Bill", "Government ID ( International passport, Voter’s ID)", "6 Months Bank Statement", "Passport Photograph", "CAC document",].map((item, index) => (
                            <Flex alignItems="center" justify="left" key={index}>
                                <Box width={2} height={2} mr={4} bg="#FA2A55" borderRadius="full" />
                                <Text my="5px">{item}</Text>
                            </Flex>
                        ))}
                    </Box>

                </Flex>

            </Flex>



            <Flex
                // alignItems={'center'}
                wrap={'wrap'}
                // align="center"
                paddingY='20px'
                mb="70px"
                direction='column'
                backgroundColor='#F2C9E3'
            >

                <Flex

                    wrap={'wrap'}

                    paddingY='20px'
                    mb="10px"
                    direction='row'
                    backgroundColor='#F2C9E3'
                    justify='space-around'
                    align="space-around"
                >


                    <Box>
                        <Text
                            color="#808080"
                            fontWeight="700"
                            fontSize="14px"
                        >
                            LGAs Available
                        </Text>
                        <Text
                            color="#33343D"
                            fontWeight="700"
                            fontSize={{ base: "60px", md: "75px" }}
                            fontFamily='heading'
                        >
                            <CountUp
                                start={14}
                                end={24}
                                smartEasingAmount={true}

                                duration={5}
                                separator=","
                                decimals={0}
                                decimal="."
                                prefix=""
                                suffix="+"
                            >
                            </CountUp>
                        </Text>

                    </Box>


                    <Box>
                        <Text
                            color="#808080"
                            fontWeight="700"
                            fontSize="14px"
                        >
                            Get Loans up to
                        </Text>
                        <Text
                            color="#33343D"
                            fontWeight="700"
                            fontSize={{ base: "60px", md: "75px" }}
                            fontFamily='heading'
                        >
                            <CountUp
                                start={2}
                                end={10}
                                smartEasingAmount={true}
                                duration={5}
                                separator=","
                                decimals={0}
                                decimal="."
                                prefix="₦"
                                suffix="M"
                            >
                            </CountUp>

                        </Text>

                    </Box>


                    <Box>
                        <Text
                            color="#808080"
                            fontWeight="700"
                            fontSize="14px"
                        >
                            Successful Applications
                        </Text>
                        <Text
                            color="#33343D"
                            fontWeight="700"
                            fontSize={{ base: "60px", md: "75px" }}
                            fontFamily='heading'
                        >
                            {/* 500+ */}
                            <CountUp
                                start={470}
                                end={500}
                                smartEasingAmount={true}
                                duration={5}
                                separator=","
                                decimals={0}
                                decimal="."
                                prefix=""
                                suffix="+"
                            >
                            </CountUp>
                        </Text>
                    </Box>


                    <Box>
                        <Text
                            color="#808080"
                            fontWeight="700"
                            fontSize="14px"
                        >
                            Apprx. Review Time
                        </Text>
                        <Text
                            color="#33343D"
                            fontWeight="700"
                            fontSize={{ base: "60px", md: "75px" }}
                            fontFamily='heading'
                        >

                            <CountUp
                                start={60}
                                end={72}
                                smartEasingAmount={true}
                                duration={5}
                                separator=","
                                decimals={0}
                                decimal="."
                                prefix=""
                                suffix="h"

                            >
                            </CountUp>
                        </Text>
                    </Box>


                </Flex>

{/* 
                <Button
                    bg="#540445"
                    borderRadius="6px"
                    marginBottom={'10'}
                    fontSize="12px"
                    fontWeight="500"
                    color="white"
                    p="13px 32px"
                    width={'200px'}
                    alignSelf={'center'}
                    justifySelf={'center'}
                    _hover={{
                        color: "#540445",
                        border: "1px solid #540445",
                        bg: "white",
                    }}
                    onClick={() => {
                        navigate("/home");
                    }}
                    textTransform="uppercase"
                >
                    APPLY FOR A LOAN
                </Button> */}

            </Flex>


            <Flex direction="row" justify="center" alignItems="end" flexWrap="wrap">
                <Img src={bgmobile} marginRight={5} objectFit="contain" w={imgWidthMM} />

                <Box
                    backgroundColor="pink.300"
                    border="border"
                    width={boxWidthMM}
                    height="500px"
                    marginTop="0px"
                    boxShadow="1px 3px 4px 4px rgba(2, 2, 2, .1)"
                    dropShadow="lg"
                    borderTopRadius="15px"
                    paddingLeft={12}
                    paddingTop={12}
                >
                    <Text marginBottom={10} color="pink.900" fontSize={fontSizeMM} fontWeight="bold">
                        START YOUR LOAN JOURNEY
                    </Text>


                    <CarouselProvider
                        naturalSlideWidth={100}
                        naturalSlideHeight={heightCarousel}
                        orientation='vertical'
                        totalSlides={4}
                        isPlaying='true'
                    >

                        <Slider>
                            <Slide index={0} >
                                <Text color="#540A45"
                                    fontWeight="700"
                                    fontSize="45px">1.</Text>
                                <Text color="#540A45"
                                    fontWeight="700"
                                    fontSize="45px" >Submit Personal</Text>

                                <Text color="#540A45"
                                    fontWeight="700"
                                    fontSize="45px" > Information</Text></Slide>


                            <Slide index={1}>
                                <Text color="#540A45"
                                    fontWeight="700"
                                    fontSize="45px">2.</Text>
                                <Text color="#540A45"
                                    fontWeight="700"
                                    fontSize="45px">Upload vital</Text>

                                <Text color="#540A45"
                                    fontWeight="700"
                                    fontSize="45px">documents. </Text>

                            </Slide>


                            <Slide index={2}>

                                <Text color="#540A45"
                                    fontWeight="700"
                                    fontSize="45px">3.</Text>

                                <Text
                                    color="#540A45"
                                    fontWeight="700"
                                    fontSize="45px" >Wait for Loan</Text>
                                <Text
                                    color="#540A45"
                                    fontWeight="700"
                                    fontSize="45px" >Review.</Text>
                            </Slide>

                            <Slide index={3}>
                                <Text color="#540A45"
                                    fontWeight="700"
                                    fontSize="45px">4.</Text>
                                <Text color="#540A45"
                                    fontWeight="700"
                                    fontSize="45px">Accept your </Text>
                                <Text color="#540A45"
                                    fontWeight="700"
                                    fontSize="45px">Loan Offer.</Text>

                            </Slide>

                        </Slider>

                    </CarouselProvider>


                </Box>
            </Flex>




            <Text
                marginTop="65px"
                color="#540A45"
                fontWeight="600"
                fontSize={titleFontSize}
                textAlign="center"
                fontFamily="heading"
                lineHeight={1}
                marginBottom="25px"
            >
                Frequently Asked Questions?
            </Text>


            <Text
                fontWeight="400"
                fontSize={subtitleFontSize}
                textAlign="center"
                fontFamily="heading"
                lineHeight={1}
                marginBottom={12}
            >
                There's a really high chance we already answered the question you have below. Choose a category to see.
            </Text>


            <Tabs 
            
                backgroundImage={loanwatermark}
                backgroundSize={'contain'}
                isFitted
                paddingX={paddingXTab} >
                <TabList overflowX={'visible'}  overflowY={'hidden'} className="faqs" >
                    {["GENERAL", "DISBURSEMENT", "GUARANTOR", "COLLATERAL", "REPAYMENT"].map((tab, index) => (
                        <Tab key={index} color="black" fontSize={fontSizeTab} fontWeight="semibold"
                        _selected={{ backgroundColor: "pink.800", color: "white" }}
                        >
                            {tab}
                        </Tab>
                    ))}
                </TabList>

                <TabPanels  backgroundColor={'whiteAlpha.700'}>

                    <TabPanel>
                        <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                            What is HerVest Loan?
                        </Text>
                        <Text textAlign="left">
                            HerVest Loan is a tailored financing solution designed to assist individuals and women-owned businesses in achieving their financial objectives quickly and easily. It provides easy access to finance that may otherwise be challenging to obtain from traditional financial institutions.
                        </Text>
                        
                        <Divider marginY={4} />

                        <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                            Is HerVest Loan for women only?
                        </Text>

                        <Text textAlign="left">
                            HerVest Loan is not exclusively for women. It is a financial solution for everyone who meets the loan requirements.
                        </Text>
                        <Divider marginY={4} />

                        <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                            What are the benefits of using HerVest Loan?
                        </Text>
                        <Text textAlign="left">
                            The benefits of HerVest Loan include:
                            <ul>
                                <li>Fast and easy application process</li>
                                <li>Minimal stress with limited documentation required</li>
                                <li>Flexible repayment terms to suit individual needs</li>
                            </ul>
                        </Text>
                        <Divider marginY={4} />

                        <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                            What types of HerVest Loans are available?
                        </Text>
                        <Text textAlign="left">
                            HerVest offers two types of loans: Personal Loans and Business Loans:
                            <ul>
                                <li>Personal Loans are for salary earners seeking financing to meet personal needs and goals.</li>
                                <li>Business Loans provide working capital to SMEs to support financial and business objectives.</li>
                            </ul>
                        </Text>
                        <Divider marginY={4} />

                        <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                            What documents are required to access a personal loan?
                        </Text>
                        <Text textAlign="left">
                            For Personal Loans:
                            <ul>
                                <li>BVN, employment and Next of Kin details</li>
                                <li>NIN number</li>
                                <li>Utility bill</li>
                                <li>Passport photograph</li>
                                <li>Government ID (NIN slip, International passport, Driver’s license, or Voter’s card)</li>
                                <li>Bank statement of 6 months</li>
                            </ul>
                        </Text>
                        <Divider marginY={4} />

                        <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                            What documents are required to access a business loan?
                        </Text>
                        <Text textAlign="left">
                            For Business Loans:
                            <ul>
                                <li>BVN, relevant business details, and a Guarantor</li>
                                <li>NIN number</li>
                            </ul>
                        </Text>
                    </TabPanel>

                    <TabPanel >
                        <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                            How will my Loan be Disbursed ?
                        </Text>
                        <Text textAlign="left">
                            After accepting your loan offer, the approved amount will be sent to your Remita Activated account.
                        </Text>

                        <Divider marginY={4} />

                        <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                            How do I activate Remita on my Account ?
                        </Text>
                        <Text textAlign="left">
                            After accepting your offer, we generate a slip and send it to your email, you will print out the slip, sign and submit to your bank
                        </Text>

                    </TabPanel>

                    <TabPanel >

                        <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                            Who needs a Guarantor ?
                        </Text>

                        <Text textAlign="left">
                            Business Loans above ₦500,000 Naira will need a Guarantor
                        </Text>

                        <Divider marginY={4} />

                        <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                            Who can be my guarantor ?
                        </Text>

                        <Text textAlign="left">
                            Your guarantor is anyone who can vouch for you.
                        </Text>

                        <Divider marginY={4} />

                        <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                            What details do I need from my Guarantor ?
                        </Text>
                        <Text textAlign="left">
                            Your guarantor will need to provide a 6 Months Bank Statement, Passport Photograph, Means of Identification and a Signed Blank Cheque.
                        </Text>

                    </TabPanel>

                    <TabPanel >
                        <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                            Is Collateral Needed?
                        </Text>
                        <Text textAlign="left">
                            HerVest require Collateral for loan amounts of 5 million Naira and above.
                        </Text>
                    </TabPanel>

                    <TabPanel  >
                        <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                            How will I repay my Loans ?
                        </Text>
                        <Text textAlign="left">
                            Ensure your remita activated account is funded with the repayment amount.
                        </Text>

                        <Divider marginY={4} />

                        <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                            Can I liquidate my Loans before maturity ?
                        </Text>
                        <Text textAlign="left">
                            Yes. Contact the HerVest loan team at care@hervest.ng
                        </Text>

                        <Divider marginY={4} />

                        <Text textAlign="left" fontWeight="bold" fontSize="18px" marginY={1}>
                            What if I default on my Repayment ?
                        </Text>
                        <Text textAlign="left">
                            4% of Principle amount will be charged as default charge.
                        </Text>



                    </TabPanel>

                </TabPanels>
            </Tabs>


            <Flex
                backgroundColor="#44223B"
                marginX={[4, 10, 70]}
                justify="space-around"
                borderRadius="20px"
                paddingTop="20"
                mt="50px"
                align="start"
                direction="row"
                flexWrap="wrap"
            >
                <div className="flex flex-col">
                    <Text
                        marginTop="65px"
                        color="white"
                        fontWeight="800"
                        fontSize={titleFontSizeLL}
                        fontFamily="heading"
                        lineHeight={1}
                        textAlign={'center'}
                    >
                        Gain access to
                    </Text>

                    <Text
                        color="white"
                        fontWeight="800"

                        fontSize={titleFontSizeLL}
                        fontFamily="heading"
                        textAlign={'center'}
                    >
                        Instant loans
                    </Text>

                    <Text marginY={textMargin} textAlign={'center'} color="white">
                        Coming soon to the HerVest app
                    </Text>
                    <Text marginY="0px" textAlign={'center'} color="white">
                        on Android and iOS Platforms
                    </Text>

                    <Flex direction="row" marginTop={marginT} marginBottom={marginB}>
                        <GooglePlayButton width={playButtonWidth} url={APKUrl} theme="light" className="playstore" />
                        <Text marginRight={5}></Text>
                        <AppStoreButton width={playButtonWidth} url={IOSUrl} theme="light" className="playstore" />
                    </Flex>
                </div>

                <Img src={footerPhoneImg} objectFit="contain" w={["100%", "50%"]} />
            </Flex>


            <Flex
                backgroundSize="cover"
                backgroundPosition="center"
                backgroundImage={bgFooter}
                direction="column"
                paddingTop="60px"
                paddingBottom="120px"
            >
                <Text marginTop={10} paddingX={textPaddingX}>
                    This site uses cookies, Cookie Policy. I agree to the terms of use, and the Disclaimer Policy. This information will not be used for marketing purposes.
                </Text>
                <Text marginTop={3} paddingX={textPaddingX}>
                    Copyright HerVest for Women Limited. All rights reserved.
                </Text>
            </Flex>


        </Box>

    );
};

export default NewLandingPage;


